export const CONSTANTS = {
  SET_LANGUAGE_PAGE: 'SET_LANGUAGE_PAGE',
};

export const setLanguagePage = (dispatch, language) => {
  const setLanguage = {
    type: CONSTANTS.SET_LANGUAGE_PAGE,
    payload: language,
  };
  dispatch(setLanguage);
  return setLanguage;
};
