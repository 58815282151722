/* eslint-disable arrow-body-style */
/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactGA from 'react-ga';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Link from 'next/link';

const MenuWrapper = styled.div`
  position: absolute;
  top: 110px;
  left: 2px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px 0px 0px rgba(181, 181, 181, 0.5);

  ${({ theme, isMenuVisible }) => theme.phone`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    transition: .3s ease-in-out;
    transform: translateY(-100%);
    z-index: -1;
    box-shadow: none;
    border-radius: 0;
    border: none;
    ${isMenuVisible && 'transform: translateY(0)'}
  `};
`;

const basicStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  line-height: 42px;
  ${({ theme }) => theme.phone`
    color: ${theme.colors.dark};
    font-family: 'Bold';
    font-size: 26px;
    line-height: 50px;
  `};
`;

const StyledLinkText = styled.p`
  cursor: pointer;
  ${basicStyles};
`;

const StyledLink = styled.a`
  ${basicStyles};
  display: block;
  text-decoration: none;
`;

const ListWrapper = styled.ul`
  padding: 12px 44px 12px 26px;
  ${({ theme }) => theme.phone`padding: 95px 25px 20px;`};
`;

const ListElement = styled.li`
  ${({ theme }) => theme.phone`
    padding: 14px 0;
    border-bottom: 1px solid #D4D4D4;
  `};
`;

const renderNavItem = (link, label, gaTag) => {
  switch (link) {
    case 'faq':
      return (
        <StyledLink
          data-test-id="faq"
          href="http://intercom.help/keller-covered/faq"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            if (gaTag) {
              ReactGA.event({
                category: 'Shared',
                action: gaTag,
              });
            }
          }}
        >
          {label}
        </StyledLink>
      );
    default:
      return (
        <Link href={`/${link}`}>
          <StyledLinkText
            data-test-id={link}
            onClick={() => {
              if (gaTag) {
                ReactGA.event({ category: 'Shared', action: gaTag });
              }
            }}
          >
            {label}
          </StyledLinkText>
        </Link>
      );
  }
};

const renderList = items =>
  items.map(item => {
    return (
      item.link !== 'legal' && (
        <ListElement key={item.link}>
          {renderNavItem(item.link, item.label, item.ga)}
        </ListElement>
      )
    );
  });

const Menu = React.forwardRef(({ items, isMenuVisible }, ref) => (
  <MenuWrapper isMenuVisible={isMenuVisible} ref={ref}>
    <ListWrapper>{renderList(items)}</ListWrapper>
  </MenuWrapper>
));

Menu.displayName = 'Menu';

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMenuVisible: PropTypes.bool.isRequired,
};

export default Menu;
