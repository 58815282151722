import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledHamburger = styled.button`
  outline: none;
  border: none;
  background: none;
  height: 25px;
  width: 30px;
  cursor: pointer;
  position: relative;
  ${({ theme }) => theme.phone`
    margin: 10px 0 10px auto;
    height: 20px;
  `};
`;

const StyledLine = styled.span`
  width: 100%;
  height: 4px;
  border-radius: 1px;
  background-color: ${({ theme, isWhiteNav }) =>
    isWhiteNav ? theme.colors.white : theme.colors.primary};
  transition: 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: ${({ top }) => top};
  ${({ openStyles }) =>
    openStyles &&
    css`
      ${openStyles};
    `};
`;

const Hamburger = React.forwardRef(
  ({ isOpen, toggleMenu, isWhiteNav }, ref) => {
    const renderLines = () => {
      const lines = [
        {
          top: '0',
          openStyles: `${
            isOpen ? 'transform: translate(-50%, 25px); opacity: 0;' : ''
          }`,
        },
        {
          top: '50%',
          openStyles: `${
            isOpen ? 'transform: translate(-50%) rotate(45deg);' : ''
          }`,
        },
        {
          top: '50%',
          openStyles: `${
            isOpen ? 'transform: translate(-50%) rotate(-45deg);' : ''
          }`,
        },
        {
          top: '100%',
          openStyles: `${
            isOpen ? 'transform: translate(-50%, -25px); opacity: 0;' : ''
          }`,
        },
      ];

      return lines.map((el, index) => (
        <StyledLine
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          top={el.top}
          isWhiteNav={isWhiteNav}
          openStyles={el.openStyles}
        />
      ));
    };

    return (
      <StyledHamburger
        data-test-id="menu"
        ref={ref}
        onClick={() => {
          toggleMenu();

          ReactGA.event({
            category: 'Shared',
            action: 'Click on menu (hamburger)',
          });
        }}
      >
        {renderLines()}
      </StyledHamburger>
    );
  }
);

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isWhiteNav: PropTypes.bool.isRequired,
};

export default Hamburger;
