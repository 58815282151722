import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

export const buttonStyles = css`
  border: none;
  outline: none;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 8px;
  border-radius: 30px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-weight: 400;
  font-family: 'Medium';
  cursor: pointer;
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ as }) => as === 'a' && 'text-decoration: none;'};
  color: ${({ isInverted, theme }) =>
    isInverted ? theme.colors.primary : theme.colors.white};
  background: ${({ isInverted, theme, color, customColor }) =>
    (color === 'dark' && theme.colors.dark) ||
    (isInverted ? theme.colors.white : theme.colors[customColor || 'primary'])};
  ${({ isEventpage }) =>
    isEventpage &&
    css`
      background-color: #0093e9;
    `};

  &:disabled {
    background: ${({ theme }) => theme.colors.grayLight};
    color: ${({ theme }) => theme.colors.paragraph.darkGray};
    cursor: not-allowed;
  }

  ${({ theme, mobileWidth }) => theme.phone`
      font-size: 16px;
      padding: 15px 8px;
      width: ${mobileWidth};
  `};
  ${({ theme, mobileMargin }) =>
    mobileMargin && theme.phone`margin: ${mobileMargin}`};
`;

const rotate = keyframes`
  0% {
    opacity: 0.2;
    width: 5px;
    height: 5px;
  }
  20% {
    opacity: 1;
    width: 11px;
    height: 11px;
  }
  100% {
    opacity: 0.2;
    width: 5px;
    height: 5px;
  }
`;

const StyledLoader = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  & i {
    animation-name: ${rotate};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    background: white;
    height: 2px;
    width: 2px;
    border-radius: 50%;
    margin-right: 8px;
    transform: translateY(6px);
  }

  & i:nth-child(2) {
    animation-delay: 0.2s;
  }

  & i:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles};

  ${({ styleAngled }) =>
    styleAngled &&
    css`
      line-height: 18px;
      height: 55px;
      border-radius: 0;
      color: #fff;
      background: ${({ theme }) => theme.colors.mortgageRed2};
    `};
  ${({ isEventpage }) =>
    isEventpage &&
    css`
      border-radius: 0px 5px 5px 0px;
      background-color: #0093e9;
    `};
`;

const ButtonPrimary = ({
  isInverted,
  color,
  width,
  children,
  isLoading,
  styleAngled,
  isEventpage,
  ...rest
}) => (
  <StyledButton
    styleAngled={styleAngled}
    isInverted={isInverted}
    width={width}
    color={color}
    isEventpage={isEventpage}
    {...rest}
  >
    {isLoading ? (
      <StyledLoader>
        <i />
        <i />
        <i />
      </StyledLoader>
    ) : (
      children
    )}
  </StyledButton>
);

ButtonPrimary.propTypes = {
  isInverted: PropTypes.bool,
  styleAngled: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isLoading: PropTypes.bool,
};

ButtonPrimary.defaultProps = {
  isInverted: false,
  styleAngled: false,
  color: '',
  width: '225px',
  isLoading: false,
};

export default ButtonPrimary;
