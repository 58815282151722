import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ButtonPrimary from './ButtonPrimary';

const StyledButton = styled(ButtonPrimary)`
  width: 201px;
  font-family: Bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  ${({ theme }) => theme.phone`
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-left: 22px;
  `};
`;

const StyledButtonCopy = styled.span`
  margin-left: 11px;
  ${({ isConfirmationPage }) => isConfirmationPage && `margin-left: 1px;`};
  i {
    transform: translateY(-3px);
  }
`;

const GetCoveredNowButton = ({
  onClick,
  isMobile,
  isConfirmationPage,
  isEo,
}) => (
  <StyledButton onClick={onClick} width="200px" color="dark">
    {isMobile ? (
      <i className="fas fa-phone" />
    ) : (
      <>
        <i
          aria-hidden
          style={{ fontSize: 16, marginRight: isConfirmationPage ? 8 : 0 }}
          className="fas fa-phone"
        />
        <StyledButtonCopy isConfirmationPage={isConfirmationPage}>
          {isEo ? 'Questions?' : 'Get covered now'}
        </StyledButtonCopy>
      </>
    )}
  </StyledButton>
);

GetCoveredNowButton.propTypes = {
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  isConfirmationPage: PropTypes.bool,
  isEo: PropTypes.bool,
};

GetCoveredNowButton.defaultProps = {
  onClick: () => {},
  isMobile: false,
  isConfirmationPage: false,
  isEo: false,
};

export default GetCoveredNowButton;
