/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import Button from 'components/Button/ButtonPrimary';
import Menu from 'components/Menu/Menu';
import GetCoveredNowButton from 'components/Button/GetCoveredNowButton';
import Hamburger from './Hamburger';
import CallModal from './CallModal';
import CoveredNowModal from './CoveredNowModal';
import { setLanguagePage } from '../../store/actions/app';

const Wrapper = styled.nav`
  position: absolute;
  z-index: 1000;
  background-color: transparent;
  width: 100%;
  transition: all 0.2s;
  ${({ theme }) => theme.phone`
    top: 0;
    position: fixed;
    background-color: ${theme.colors.white};
    z-index: 100;
  `};
`;

const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({ theme }) => theme.desktopSmall`padding: 30px 25px;`};
  ${({ theme }) => theme.phone`
    background-color: ${theme.colors.white};
    padding: 21px 25px;
    box-shadow: 0px 1px 10px 0px #cacacb;
  `};
`;

const StyledLogoContainer = styled.div`
  margin: 15px 0px 0px 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  ${({ theme }) => theme.phone`
    position: static;
    order: -1;
    transform: translate(-5px, 0);
  `};
`;

const StyledSvg = styled(SVG)`
  height: 45px;
  width: 188px;
  cursor: pointer;
  ${({ iswhitenav, theme }) =>
    iswhitenav &&
    css`
      path {
        fill: ${theme.colors.white};
      }
    `};
  ${({ theme }) => theme.phone`
    display: none;
  `};
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.phone`
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-left: 22px;
    i {
      transform: translateY(-3px);
    }
  `};
`;

const StyledButtonCopy = styled.span`
  margin-left: 11px;
  i {
    transform: translateY(-3px);
  }
`;

const StyledImg = styled.img`
  display: none;
  cursor: pointer;
  ${({ theme }) => theme.phone`
    display: block;
    height: 36px;
    width: 138px;
    margin-top: -8px;
  `};
`;

const StyledContainerToogle = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0px 0px 0px;
`;

const StyledToogleTitleEnglish = styled.span`
  margin: 0px 50px 0px 0px;
  font-size: 18px;
  cursor: pointer;
  color: #0093e9;
  ${({ theme }) => theme.phone`
    margin: -1px 75px -11px 0px;
`};
`;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
      isMobile: false,
      isModalVisible: false,
    };
    this.menuContainer = React.createRef();
    this.hamburger = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClicking);
    window.addEventListener('resize', this.handleResize);
    this.setState({ isMobile: window.innerWidth < 690 });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClicking);
    window.removeEventListener('resize', this.handleResize);
  }

  handleClicking = e => {
    const { isMenuVisible, isMobile } = this.state;
    if (
      this.menuContainer.current &&
      this.hamburger.current &&
      !this.menuContainer.current.contains(e.target) &&
      !this.hamburger.current.contains(e.target) &&
      isMenuVisible &&
      !isMobile
    ) {
      this.setState({ isMenuVisible: false });
    }
  };

  handleResize = () => this.setState({ isMobile: window.innerWidth < 690 });

  toggleHamburger = () =>
    this.setState(({ isMenuVisible }) => ({
      isMenuVisible: !isMenuVisible,
    }));

  toggleModal = () => {
    this.setState(({ isModalVisible }) => ({
      isModalVisible: !isModalVisible,
    }));

    ReactGA.event({
      category: 'Shared',
      action: 'Click phone button - open insurancematch window',
    });
  };

  changeLanguage = value => {
    setLanguagePage(this.props.dispatch, value);
    localStorage.setItem('language', JSON.stringify(value));
  };

  render() {
    const { items, isWhiteNav, isEo = false, state } = this.props;
    const { isMenuVisible, isMobile, isModalVisible } = this.state;
    const verifyWhiteMobileNav = isWhiteNav && !isMobile ? 1 : 0;
    return (
      <>
        <Wrapper>
          <StyledContainer>
            <Hamburger
              isWhiteNav={isWhiteNav && !isMobile}
              ref={this.hamburger}
              isOpen={isMenuVisible}
              toggleMenu={this.toggleHamburger}
            />

            <StyledLogoContainer>
              <Link href="/">
                <a
                  onClick={() => {
                    ReactGA.event({
                      category: 'Shared',
                      action: 'Click logo',
                    });
                  }}
                >
                  <StyledImg
                    src="/static/img/logoKW.svg"
                    alt="KWlogo"
                    data-test-id="logo"
                  />
                </a>
              </Link>
              <Link href="/">
                <a
                  onClick={() => {
                    ReactGA.event({
                      category: 'Shared',
                      action: 'Click logo',
                    });
                  }}
                >
                  <StyledSvg
                    iswhitenav={verifyWhiteMobileNav}
                    src="/static/img/logoKW.svg"
                    data-test-id="logo"
                  />
                </a>
              </Link>
              <StyledContainerToogle>
                {!isEo ? (
                  isMobile ? (
                    <>
                      {state.language === 'es-MX' ? (
                        <StyledToogleTitleEnglish
                          state={state}
                          onClick={() => {
                            this.changeLanguage('en-US');

                            ReactGA.event({
                              category: 'Shared',
                              action: 'Click English',
                            });
                          }}
                          style={{
                            fontWeight: state.language === 'en-US' ? 600 : 300,
                          }}
                        >
                          English
                        </StyledToogleTitleEnglish>
                      ) : (
                        <StyledToogleTitleEnglish
                          state={state}
                          onClick={() => {
                            this.changeLanguage('es-MX');

                            ReactGA.event({
                              category: 'Shared',
                              action: 'Click Spanish',
                            });
                          }}
                          style={{
                            fontWeight: state.language === 'es-MX' ? 600 : 300,
                          }}
                        >
                          Español
                        </StyledToogleTitleEnglish>
                      )}
                    </>
                  ) : (
                    <>
                      <StyledToogleTitleEnglish
                        state={state}
                        language="en"
                        onClick={() => {
                          this.changeLanguage('en-US');

                          ReactGA.event({
                            category: 'Shared',
                            action: 'Click English',
                          });
                        }}
                        style={{
                          fontWeight: state.language === 'en-US' ? 600 : 300,
                        }}
                      >
                        English
                      </StyledToogleTitleEnglish>
                      <StyledToogleTitleEnglish
                        state={state}
                        language="es"
                        onClick={() => {
                          this.changeLanguage('es-MX');

                          ReactGA.event({
                            category: 'Shared',
                            action: 'Click Spanish',
                          });
                        }}
                        style={{
                          fontWeight: state.language === 'es-MX' ? 600 : 300,
                        }}
                      >
                        Español
                      </StyledToogleTitleEnglish>
                    </>
                  )
                ) : null}
              </StyledContainerToogle>
            </StyledLogoContainer>

            {((isMobile && !isMenuVisible) || !isMobile) &&
              (isEo ? (
                <GetCoveredNowButton
                  isMobile={isMobile}
                  onClick={this.toggleModal}
                  isEo
                />
              ) : (
                <StyledButton
                  onClick={this.toggleModal}
                  width="200px"
                  color="dark"
                >
                  {isMobile ? (
                    <i className="fas fa-phone" />
                  ) : (
                    <>
                      <i className="fas fa-phone" />
                      <StyledButtonCopy className="mediumFont">
                        (512) 855-7151
                      </StyledButtonCopy>
                    </>
                  )}
                </StyledButton>
              ))}
            {(isMenuVisible || isMobile) && (
              <Menu
                isMenuVisible={isMenuVisible}
                ref={this.menuContainer}
                items={items}
              />
            )}
          </StyledContainer>
        </Wrapper>
        {isModalVisible &&
          (isEo ? (
            <CoveredNowModal
              isModalOpen={isModalVisible}
              closeModal={this.toggleModal}
            />
          ) : (
            <CallModal
              isModalOpen={isModalVisible}
              closeModal={this.toggleModal}
            />
          ))}
      </>
    );
  }
}

Navigation.propTypes = {
  items: PropTypes.any.isRequired,
  isWhiteNav: PropTypes.bool.isRequired,
  isEo: PropTypes.bool,
};

Navigation.defaultProps = {
  isEo: false,
};

export default Navigation;
